// src/App.js
import React from "react";
import "./App.css";
import { ReactComponent as Logo } from "./wasteer_logo.svg"; // Import SVG as a React component

function App() {
  return (
    <div className="App">
      <div className="logo-container">
        {/* Wrap the logo in an <a> tag for linking */}
        <a href="https://wasteer.de" target="_blank" rel="noopener noreferrer">
          <Logo className="logo" />
        </a>
      </div>
    </div>
  );
}

export default App;
